import { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.scss";
import LoadingSpinnerPage from "./components/common/loading/LoadingSpinnerPage.js";

const LandingContainer = lazy(() => import("./components/content/landing/LandingContainer.js"));
const GameContainer = lazy(() => import("./components/content/game/GameContainer.js"));
const MarketplaceContainer = lazy(() => import("./components/content/marketplace/MarketplaceContainer.js"));
const TestMintContainer = lazy(() => import("./components/content/testMint/TestMintContainer.js"));
const NotFoundContainer = lazy(() => import("./components/content/notFound/NotFoundContainer.js"));

export default function App() {
    return (
		<BrowserRouter>
			<Suspense fallback={<LoadingSpinnerPage/>}>
				<Routes>
					<Route exact path="/" element={<LandingContainer/>}/>

					<Route exact path="/game" element={<GameContainer/>}/>
					<Route exact path="/marketplace" element={<MarketplaceContainer/>}/>
					
					<Route exact path="/testmint" element={<TestMintContainer/>}/>

					<Route path="*" element={<NotFoundContainer/>}/>
				</Routes>
			</Suspense>
		</BrowserRouter>
    );
}